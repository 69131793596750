<template>
  <div>
    <NuxtLayout>
      <NuxtLoadingIndicator :color="'#000'" />
      <VitePwaManifest />
      <NuxtPage :navigationFrom="navigationFrom" />
    </NuxtLayout>
  </div>
</template>

<script setup>
import { useCraftStore } from '@/store/craft.js'

const { locale } = useI18n()

const router = useRouter()

// Passo il guard to come props alla view per avere il corretto back nella scheda prodotto
const navigationFrom = ref(null)
router.afterEach((to, from) => {
  navigationFrom.value = from
})

// INZIO TODO
// TODO: Evitare il watch e quando rispondono implementare https://github.com/nuxt-modules/apollo/discussions/529
await useAsyncData(() => useCraftStore().fetchConfiguration())

watch(
  () => locale.value,
  async () => {
    await useAsyncData(() => useCraftStore().fetchConfiguration())
  }
)

// Inizializzo lo scroller e lo inietto nella app ion modo da averlo sempre accessibile
const { lenis } = useLenis()
provide('lenis', lenis)

/* 
const route = useRoute()

watch(
  () => route.name,
  () => {
    lenis.value.scrollTo(0, { immediate: true })
  }
) */
</script>
