import revive_payload_client_4OP1JzJdnx from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.11_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_stylelint@14.16.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ZLFyGmk90U from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.11_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_stylelint@14.16.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_avEWk9IKjX from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.11_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_stylelint@14.16.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_dPe4Gu8G6O from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.15_rollup@4.18.1_vite@5.3.4_vue@3.4.33/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_Gv6TtpgD7y from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.11_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_stylelint@14.16.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_2gDjc1unp0 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.11_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_stylelint@14.16.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_j1YLmjjy47 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.11_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_stylelint@14.16.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_OlKITrTivL from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.11_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_stylelint@14.16.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_aHGnoyA4sp from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.18.1_typescript@5.5.3_vue@3.4.33/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_WwAqZddESi from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.11_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_stylelint@14.16.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import templates_pwa_client_58668b19_4yrS1X6Dg2 from "/opt/build/repo/.nuxt/templates.pwa.client.58668b19.ts";
import plugin_PeTjCn1Flr from "/opt/build/repo/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.12.4_rollup@4.18.1_vue@3.4.33/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_H2jiaXlxMC from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.14_rollup@4.18.1_typescript@5.5.3_vue@3.4.33/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import i18n_2LAdLIBPMD from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.18.1_vue@3.4.33/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import animation_directives_client_BcJ9PYvc9H from "/opt/build/repo/plugins/animation-directives.client.js";
import apollo_IRWTEOPleP from "/opt/build/repo/plugins/apollo.js";
import leaflet_client_trb2AG60Fs from "/opt/build/repo/plugins/leaflet.client.js";
import sentry_client_GoGQuZo4Et from "/opt/build/repo/plugins/sentry.client.js";
export default [
  revive_payload_client_4OP1JzJdnx,
  unhead_ZLFyGmk90U,
  router_avEWk9IKjX,
  _0_siteConfig_dPe4Gu8G6O,
  payload_client_Gv6TtpgD7y,
  navigation_repaint_client_2gDjc1unp0,
  check_outdated_build_client_j1YLmjjy47,
  chunk_reload_client_OlKITrTivL,
  plugin_vue3_aHGnoyA4sp,
  components_plugin_KR1HBZs4kY,
  prefetch_client_WwAqZddESi,
  templates_pwa_client_58668b19_4yrS1X6Dg2,
  plugin_PeTjCn1Flr,
  plugin_H2jiaXlxMC,
  i18n_2LAdLIBPMD,
  animation_directives_client_BcJ9PYvc9H,
  apollo_IRWTEOPleP,
  leaflet_client_trb2AG60Fs,
  sentry_client_GoGQuZo4Et
]